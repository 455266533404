// ** Redux Imports
import {combineReducers} from 'redux'

// ** Reducers Imports
import auth from './auth/authReducer'
import orders from './orders/ordersReducer'
import shipping from './orders/shippingReducer'
import awaitingForOrders from './orders/awaitingForOrdersReducer'
import delivered from '../reducers/orders/deliveredReducer'
import navbar from './navbar'
import layout from './layout'
import chat from '@src/views/apps/chat/store/reducer'
import todo from '@src/views/apps/todo/store/reducer'
import users from '@src/views/apps/user/store/reducer'
import email from '@src/views/apps/email/store/reducer'
import invoice from '@src/views/apps/invoice/store/reducer'
import calendar from '@src/views/apps/calendar/store/reducer'
import ecommerce from '@src/views/apps/ecommerce/store/reducer'
import dataTables from '@src/views/tables/data-tables/store/reducer'
import calculate from './calculate/calculateReducer'
import manifest from './orders/manifestReducer'
import common from './commonReducer/commonReducer'
import shippingRules from './settings/shippingRulesReducer'
import customsDeclarationRules from './settings/customsDeclarationRulesReducer'
import upcReducer from './upc/upcReducer'
import Roles from './settings/RolesReducer'
import permissionsReducer from './permissions/permissionsReducer'
import Users from './settings/UsersReducer'
import workLogReducer from "./workLog/workLogReducer"
import Rates from "./settings/Rates"
import uploadingFiles from "./settings/uploadingFiles"
import CarriersCoefficientsReducer from "./settings/CarriersCoefficientsReducer"
import CarriersCoefficients_Log_Reducer from "./settings/CarriersCoefficients_Log_Reducer"
import CarriersCoefficients_ErrorLog_Reducer from "./settings/CarriersCoefficients_ErrorLog_Reducer"
import InvoiceReducer from "./settings/InvoiceReducer"
import companyAddressReducer from './settings/CompanyAddressReducer'
import ConveyorLineGateRulesReducer from './settings/ConveyorLineGateRulesReducer'
import ShopCoefficientsReducer from "./settings/ShopsCoefficientsReducer"
import hsCodesReducer from "./settings/hsCodesReducer"
import DimWeightReducer from "./settings/DimWeightReducer"
import problemsReducer from "./upc/problemsReducer"
import uploadReturnLabelsReducer from "./settings/uploadReturnLabels"
import AppErrorReducer from "./settings/AppErrorReducer"
import tagReducer from "./tag/tagReducer"
import OrderItemBinReducer from "./settings/OrderItemBinReducer"
import ManufacturerReducer from './settings/ManufacturerReducer'

const rootReducer = combineReducers({
    auth,
    orders,
    shippingRules,
    customsDeclarationRules,
    Roles,
    Users,
    permissionsReducer,
    workLogReducer,
    shipping,
    Rates,
    hsCodesReducer,
    uploadingFiles,
    tagReducer,
    OrderItemBinReducer,
    CarriersCoefficientsReducer,
    ShopCoefficientsReducer,
    CarriersCoefficients_Log_Reducer,
    CarriersCoefficients_ErrorLog_Reducer,
    uploadReturnLabelsReducer,
    AppErrorReducer,
    companyAddressReducer,
    ConveyorLineGateRulesReducer,
    InvoiceReducer,
    DimWeightReducer,
    manifest,
    ManufacturerReducer,
    awaitingForOrders,
    problemsReducer,
    calculate,
    delivered,
    common,
    todo,
    chat,
    email,
    users,
    navbar,
    layout,
    invoice,
    calendar,
    ecommerce,
    dataTables,
    upcReducer
})

export default rootReducer
