import SettingsApi from "../../api/SettingsApi/SettingsApi"

import {
  SET_MANUFACTURER_LIST
} from "../../constants/constants"
import {
  CheckAndNotify,
  CheckAndNotifyErrors,
  LogOutOnAuthError
} from "../orders/ordersReducer"

const initialState = {
  manufacturerList: []
}

const ManufacturerReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_MANUFACTURER_LIST:
      return {
        ...state,
        manufacturerList: [...action.payload]
      }
    default:
      return state
  }
}

export const setManufacturerList = (payload) => ({
  type: SET_MANUFACTURER_LIST,
  payload
})


export const getManufacturerList = () => (dispatch, getState) => {
  SettingsApi.getManufacturerList(getState().auth.AuthToken)
    .then((response) => {
      CheckAndNotify(response)
      dispatch(setManufacturerList(response.data.data))
    })
    .catch((error) => {
      if (error) {
        dispatch(LogOutOnAuthError(error))
        CheckAndNotifyErrors(error)
      }
    })
}
export const updateManufacturer = (props) => (dispatch, getState) => {
  SettingsApi.updateManufacturer(getState().auth.AuthToken, props)
    .then((response) => {
      CheckAndNotify(response)
      dispatch(getManufacturerList())
    })
    .catch((error) => {
      if (error) {
        dispatch(LogOutOnAuthError(error))
        CheckAndNotifyErrors(error)
      }
    })
}


export default ManufacturerReducer
